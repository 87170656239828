import React from "react"
import Layout from "../components/layout";
import {Helmet} from "react-helmet";
import {StaticImage} from "gatsby-plugin-image";
import ContactSection from "../components/sections/contact-section";

const ArchitecturePage = () => {
    return (
        <Layout>
            <Helmet>
                <title>SG-1.online - Architecture</title>
            </Helmet>
            <main>
                <section className="content-section">
                    <div className="container">
                        <div className={'flex flex-wrap md:flex-nowrap'}>
                            <div className={'basis-full md:basis-1/2'}>
                                <h1 className={'h1'}>SG-1 Validator Architecture</h1>

                                <div className={'subtitle gradient-text'}>
                                    On the architecture of the server setup, the sentry nodes are the first front.
                                </div>

                                <div className={'hero-text'}>
                                    Those are connected to other nodes in the Cosmos ecosystem and gossip about newest transactions, blocks and updates. The IP of the sentry is public.
                                </div>

                                <hr/>

                                <div className={'content-text'}>
                                    <p>
                                        The validator nodes are in direct contact with the sentry nodes. The IP of validator nodes is private, they have a carefully crafted firewall and whitelist IP connection range of the sentry nodes. Also the tendermint instance is only aware of the sentry nodes IP addresses. The validator node holds a private key generated by a KMS of our choice, where the passphrase is held in a hardware wallet of our choice in a location that is known only to a few people. A minimum of 1 validator node should always be online.
                                    </p>
                                    <p>
                                        Relayer nodes are still experimental in design and also for us. It should be carefully examined if they actually make the system more resilient and provide a reliable anti-DDOS protection or rather can be an additional fail-system where we have to be careful.
                                    </p>
                                    <p>
                                        Backup nodes, for us usually limited to 1-2 per network, have a higher disk capacity than the other servers and act as creating backups. They shut down the gaiad for the time of taking the backup in an interval between 48 and 72 hours. So we always have a good state to use in case we need to deploy new servers and are quickly up-to-date with the blockchain.
                                    </p>
                                    <p>
                                        With building this infrastructure, we keep in mind to distribute our servers splitted with own managed servers and servers hosted by different cloud providers. This enables us to scale geographically as well as in storage, computing and bandwith. Cost of a provider is not always the first indicator for us what to choose. But security, control, location and tools will always play a role in deciding which provider to choose.

                                    </p>
                                </div>

                            </div>
                            <div className={'basis-full md:basis-1/2'}>
                                <div>
                                    <StaticImage className='content-image scalable' placeholder='none' src='../images/architecture-illustration.svg' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <ContactSection/>
        </Layout>
    )
}

export default ArchitecturePage;
